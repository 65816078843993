<template>
    <b-row class="rowbg">
        <b-col md="12">
            <b-card>
                <b-card-title>Algemene Voorwaarden</b-card-title>
                <b-card-text>
                    <h2 data-pm-slice="0 0 []">Algemene voorwaarden</h2>
<p><strong>Algemene Voorwaarden Ridder en de Prins</strong> versie 03-04-2024</p>
<p><strong>Artikel 1 – Definities</strong></p>
<p>In deze voorwaarden wordt verstaan onder:</p>
<ol class="ak-ol">
    <li><strong>Sinterklaasshow:</strong><br>Interactieve show met Sinterklaas en Pieten voor bedrijven of verenigingen op de locatie van de opdrachtgever of een door de opdrachtgever beschikbaar gestelde locatie</li>
    <li><strong>Instructie Sinterklaasshow:</strong><br>Instructieformulier t.b.v. de Sinterklaasshow met afspraken over in ieder geval: grote boekformulieren, techniek, inrichting en aankleding van de ruimte, cadeaus en contactgegevens. Het instructieformulier wordt voorafgaand aan de Sinterklaasshow, in overleg met de opdrachtgever opgesteld.</li>
    <li><strong>Theatervoorstelling:</strong><br>Theatervoorstelling met Sint en Piet in de hoofdrol, geproduceerd en uitgevoerd door Ridder en de Prins Entertainment.</li>
    <li><strong>Algemene Technische Ryder:</strong><br>Technische Ryder t.b.v. de Theatervoorstelling met benodigdheden op het gebied van logistieke voorzieningen, toneelvloer, podiumdelen, licht, geluid, video en overige techniek. In navolging van de Algemene Technische Ryder volgt een Specifieke Technische Ryder welke, zonder overleg, geen technische aspecten bevat die niet in de Algemene Technische Ryder zijn opgenomen;</li>
    <li><strong>Huisbezoek:</strong><br>Bezoek aan huis door Sint en Piet of twee Pieten bij één of meerdere families of gezelschappen.</li>
</ol>
<p>&nbsp;</p>
<p><strong>Artikel 2 – Algemeen</strong></p>
<ol class="ak-ol">
    <li>Het intellectuele eigendom van het gehele concept of ieder deel afzonderlijk, is het onvervreemdbaar eigendom van Ridder en de Prins Entertainment. Het is niet toegestaan om het concept of delen daarvan zonder schriftelijke toestemming van Ridder en de Prins Entertainment te gebruiken, te kopiëren of aan derden ter inzage te geven.</li>
    <li>Ridder en de Prins Entertainment heeft het recht, tenzij anders overeengekomen, de (bedrijfs-) naam van de opdrachtgever te vermelden in haar eigen promotionele kanalen.</li>
    <li>Ridder en de Prins Entertainment kan, voor wat betreft de afspraken zoals genoteerd in de offerte dan wel factuur, niet aansprakelijk worden gesteld voor kosten hoger dan het factuurbedrag.</li>
</ol>
<p>&nbsp;</p>
<p><strong>Artikel 3 – Sinterklaasshow</strong></p>
<ol class="ak-ol">
    <li>Ridder en de Prins Entertainment stuurt de opdrachtgever één exemplaar Algemene Voorwaarden toe. De opdrachtgever geeft door ondertekening van het Bevestigingsformulier aan akkoord te zijn met de Algemene Voorwaarden.</li>
    <li>Na ontvangst van het Bevestigingsformulier boekt Ridder en de Prins Entertainment de activiteit in op de gezamenlijk overeengekomen datum en tijd. Deze staan vermeld op het Bevestigingsformulier.</li>
    <li>Na de ontvangst van de bevestiging van de opdracht is een betaling van 100% van de kosten van de activiteit verplicht. Ridder en de Prins Entertainment factureert het bedrag in één keer, tenzij anders overeengekomen. De betaling dient uiterlijk de laatste werkdag voor de activiteit binnen te zijn op de rekening van Ridder en de Prins Entertainment. Indien de opdrachtgever niet tijdig de factuur voldoet, behoudt Ridder en de Prins Entertainment zich het recht voor om, na overleg met de opdrachtgever, de boeking te ontbinden.</li>
    <li>Een <strong>Sinterklaasshow</strong> bestaat uit een interactief optreden van Sinterklaas en Pieten in keurig flitsende kleding, tenzij uitdrukkelijk anders met de opdrachtgever overeengekomen en in de offerte genoteerd.</li>
    <li>De opdrachtgever ontvangt na het retourneren van het Bevestigingsformulier Sinterklaasshow de <strong>Instructie Sinterklaasshow</strong>, format voor het aanleveren van informatie over de kinderen en factuur.</li>
    <li>De opdrachtgever zorgt voor een geschikte locatie, faciliteiten en organisatie voor de activiteit zoals vastgesteld in de <strong>Instructie Sinterklaasshow</strong>.</li>
    <li>De opdrachtgever zorgt vooraf voor de benodigde informatie over de groep aanwezigen. Dit betekent het controleren, zo nodig aanvullen en retourneren van de ingevulde <strong>Instructie Sinterklaasshow</strong> en het verstrekken van informatie per kind via het aangeleverde format.</li>
</ol>
<p>&nbsp;</p>
<p><strong>Artikel 4 – Theatervoorstelling</strong></p>
<ol class="ak-ol">
    <li>Ridder en de Prins Entertainment stuurt de opdrachtgever één exemplaar Algemene Voorwaarden toe. De opdrachtgever geeft door ondertekening van het Bevestigingsformulier aan akkoord te zijn met de Algemene Voorwaarden.</li>
    <li>Na ontvangst van het Bevestigingsformulier boekt Ridder en de Prins Entertainment de activiteit in op de gezamenlijk overeengekomen datum en tijd. Deze staan vermeld op het Bevestigingsformulier.</li>
    <li>Na de ontvangst van de bevestiging van de opdracht is een betaling van 100% van de kosten van de activiteit verplicht. Ridder en de Prins Entertainment factureert het bedrag in één keer, tenzij anders overeengekomen. De betaling dient uiterlijk de laatste werkdag voor de activiteit binnen te zijn op de rekening van Ridder en de Prins Entertainment. Indien de opdrachtgever niet tijdig de factuur voldoet(,) behoudt Ridder en de Prins Entertainment zich het recht voor om, na overleg met de opdrachtgever, de boeking te ontbinden.</li>
    <li>De opdrachtgever zorgt voor een theaterzaal, podium en technische faciliteiten zoals opgenomen in de <strong>Algemene Technische Ryder</strong>, welke ter beschikking gesteld kan worden door Ridder en de Prins Entertainment. Uiterlijk één maand voor de activiteit stelt Ridder en de Prins Entertainment een Specifieke Technische Ryder op. Deze bevat, zonder overleg, geen technische aspecten die niet in de <strong>Algemene Technische Ryder</strong> opgenomen zijn.</li>
    <li>De opdrachtgever is, tenzij anders overeengekomen, verantwoordelijk voor de promotie en kaartverkoop van de voorstelling.</li>
    <li>De opdrachtgever is te allen tijde verantwoordelijk voor ontvangst, begeleiding en veiligheid van de bezoekers van de voorstelling. Ridder en de Prins Entertainment kan niet aansprakelijk gesteld worden voor enig letsel of schade van bezoekers tijdens de activiteit.</li>
</ol>
<p>&nbsp;</p>
<p><strong>Artikel 5 – Huisbezoek</strong></p>
<ol class="ak-ol">
    <li>Opdrachtgever geeft door de datum en tijd van de opdracht te bevestigen aan akkoord te zijn met de Algemene Voorwaarden.</li>
    <li>Na bevestiging van de opdracht boekt Ridder en de Prins Entertainment de activiteit in op de gezamenlijk overeengekomen datum en tijd.</li>
    <li>Na de ontvangst van de factuur van de opdracht is een betaling van 100% van de kosten van de activiteit verplicht. Ridder en de Prins Entertainment factureert het bedrag in één keer, tenzij anders overeengekomen. De opdrachtgever dient de factuur binnen twee weken te voldoen. Indien de opdrachtgever niet tijdig de factuur voldoet, behoudt Ridder en de Prins Entertainment zich het recht voor om, na overleg met de opdrachtgever, de boeking te ontbinden.</li>
    <li>Een <strong>Huisbezoek</strong> bestaat uit een bezoek van 20 minuten van Sinterklaas en één Piet of twee Pieten , in keurig flitsende kleding, tenzij uitdrukkelijk anders met de opdrachtgever overeengekomen.</li>
    <li>De opdrachtgever zorgt voor een geluidsinstallatie met cd-speler of toegang tot Spotify.</li>
    <li>De opdrachtgever zorgt vooraf voor de benodigde informatie over de groep aanwezigen. Dit betekent het invullen en retourneren van de Algemene Informatie en de informatie per aanwezige via het bij de factuur meegeleverde format.</li>
</ol>
<p><strong>Artikel 6 - Privacy</strong></p>
<p>De manier waarop wij omgaan met de door jou verstrekte persoonsgegevens is vastgelegd in ons <a href="https://www.ridderendeprins.nl/privacy-statement">privacy statement</a>.</p>
<p>&nbsp;</p>
<!--<p>Deze algemene voorwaarden zijn ook beschikbaar als <a target="_blank" rel="noopener noreferrer" href="https://media.ridderendeprins.nl/images/RedP/legal/Algemene_Voorwaarden_RedP_09-02-2020.pdf">download</a>.</p>-->
                </b-card-text>
            </b-card>
        </b-col>
    </b-row>
</template>

<script>
    export default {
        name: 'Voorwaarden'
    }
</script>

<style>

</style>